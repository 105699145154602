import { ref, onMounted } from '@vue/composition-api'
import footerDataTables from '@/fake-db/footerDataTables.json'
import { api } from '@/services/api'
import { removeComma } from "@/services/filters";
import store from "@/store"
import moment from "moment/moment";

export default function useTracking() {

    const XLSX = require('xlsx')

    const textSearch = ref('')

    const order_transportation_text = ref('')

    const headers = ref([
        {
            text: 'เลขออเดอร์',
            value: 'order_code',
            width: '140',
        },
        {
            text: 'ลูกค้า',
            value: 'customer_fname',
            width: '200',
        },
        {
            text: 'ชื่อผู้รับสินค้า',
            value: 'order_transportation_name',
            width: '200',
        },
        {
            text: 'ผู้ทำรายการ',
            value: 'user_create',
            width: '150',
        },
        {
            text: 'วันที่จัดส่ง',
            value: 'order_transportation_date',
            width: '180',
        },
        {
            text: 'จำนวนเงิน',
            value: 'order_total',
            width: '160',
            align: 'end',
        },
        {
            text: 'สถานะจัดส่ง',
            value: 'order_transportation_status',
            width: '170',
            align: 'center',
        },
        {
            text: 'ตัวเลือก',
            value: 'actions',
            width: '120',
            align: 'center fixed',
        },
    ])

    const itemsPerPage = ref(10)

    const dataList = ref([])

    const loading = ref(false)

    const isUpdateStatus = ref(false)

    const dataEdit = ref({})

    const footer = ref(footerDataTables)

    const isEdit = ref(false)

    const isAdd = ref(false)

    const selectedRows = ref([])

    const status = ref('1')

    const exportLoading = ref(false)

    const isCreateLink = ref(false)

    const moment = require('moment')

    const start = ref(moment(new Date()).format('YYYY-MM-DD'))

    const end = ref(moment(new Date()).format('YYYY-MM-DD'))

    const addPayload = (dateStart, dateEnd) => {
        start.value = dateStart
        end.value = dateEnd
        getTracking()
    }

    const options = ref([{
        text: 'ทั้งหมด',
        value: '',
    },
        {
            text: 'ร้านค้า',
            value: '2',
        },
        {
            text: 'Data center',
            value: '1',
        }
    ])

    const selection = ref('')

    const idList = ref([])

    const isShowDetail = ref(false)

    const fileImportTracking = ref(null)

    const isImport = ref(false)

    const importLoading = ref(false)

    const dataLink = ref({})

    const changeStatus = (id) => {
        status.value = id
        getTracking()
    }

    const getTracking = () => {
        loading.value = true
        api.get({
            path: '/manager/tracking',
            param: `?search=${ textSearch.value }&status=0&start=${ start.value }&end=${ end.value }&currency_id=${ localStorage.getItem('currencyId') }`,
        }).then(({ data }) => {
            dataList.value = data
            loading.value = false
        }).catch(error => {
            console.log('error :', error)
            loading.value = false
        })
    }

    onMounted(() => {
        getTracking()
    })

    const mapIdExportTracking = id => {
        if (id) {
            idList.value = [id]
        } else {
            idList.value = selectedRows.value.map(item => item.order_id)
        }
        exportTracking()
    }

    const exportTracking = () => {
        api.get({
            path: '/exportTracking',
            param: `?order_id=${ idList.value.toString().split(',') }&currency_id=${ localStorage.getItem('currencyId') }`,
        }).then(({ data }) => {
            exportExcel(data)
        }).catch(error => {
            console.log('error :', error)
        })
    }

    const exportExcel = async (data) => {
        exportLoading.value = true
        const fileName = `Tracking.xls`
        const Heading = [
            [
                `받는사람`,
                '전화번호1',
                `우편번호`,
                '주소',
                `수량(A타입)`,
                '상품명1',
                `운임구분`,
                `운임`,
                `주문자`,
                `주문번호`,
            ],
        ]

        const ws = XLSX.utils.aoa_to_sheet(Heading)

        const dataExport = await removeComma(JSON.parse(JSON.stringify(data)))
        XLSX.utils.sheet_add_json(ws, dataExport, {
            header: [
                'order_transportation_name',
                'order_transportation_phone',
                'order_transportation_zipcode',
                'transportation_address',
                'order_transportation_amount',
                'foodType',
                'foodNumber',
                'order_transportation_price',
                'customer_fullname',
                'order_code',

            ],
            skipHeader: true,
            origin: -1,
        })
        const wb = XLSX.utils.book_new()
        const wscols = [
            { wch: 26 },
            { wch: 26 },
            { wch: 26 },
            { wch: 50 },
            { wch: 26 },
            { wch: 26 },
            { wch: 26 },
            { wch: 26 },
            { wch: 26 },
            { wch: 26 },
        ]
        ws['!cols'] = wscols
        XLSX.utils.book_append_sheet(
            wb,
            ws,
            `Tracking`,
        )

        XLSX.writeFile(wb, fileName)
        setTimeout(() => {
            exportLoading.value = false
        }, 1500)
    }

    const importTracking = () => {
        importLoading.value = true
        let formData = new FormData()
        formData.append('tracking', fileImportTracking.value)

        api.post({
            path: '/confirmTracking',
            body: formData,
        }).then(res => {
            store.commit('app/ALERT', {
                message: res.message,
                color: res.response ? 'success' : 'error',
            })
            if (res.response) {
                isImport.value = false
            }
            importLoading.value = false
            getTracking()
            console.log('res :', res)
        }).catch(error => {
            importLoading.value = false
            console.log('error :', error)
        })
    }

    const generateLink = () => {
        api.put({
            path: '/orderTransportation',
            param: `${ dataEdit.value.order_id }`,
            body: {
                order_transportation_text: order_transportation_text.value,
                order_code: dataEdit.value.order_code,
            }
        }).then(({ data }) => {
            console.log('data')
            dataLink.value = data
        }).catch(error => {
            console.log('error :', error)
        })
    }

    const copy = () => {
        store.commit('app/ALERT', {
            message: 'คัดลอกเรียบร้อย',
            color: 'success',
        })
    }


    return {
        copy,
        generateLink,
        dataLink,
        isCreateLink,
        order_transportation_text,
        importLoading,
        mapIdExportTracking,
        importTracking,
        textSearch,
        headers,
        itemsPerPage,
        isAdd,
        isShowDetail,
        isEdit,
        selection,
        options,
        dataEdit,
        footer,
        fileImportTracking,
        isUpdateStatus,
        status,
        isImport,
        dataList,
        loading,
        selectedRows,
        addPayload,
        changeStatus,
        getTracking,
    }
}
