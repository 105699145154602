<template>
    <div>
        <v-card>
            <v-card-title class="px-2">
        <span>
          Tracking
        </span>
                <v-spacer></v-spacer>
                <v-btn color='primary' class="me-2" :disabled="!selectedRows.length" @click="mapIdExportTracking(null)">
                    Tracking
                    Export
                </v-btn>
                <v-btn color='info' @click="fileImportTracking = null;isImport = true">Tracking Import</v-btn>
            </v-card-title>
            <DateFilters :default-status="1" @onSendDate='addPayload'/>
            <v-row class='px-2 pb-2'>
                <v-col cols='12' md='6' lg='4'>
                    <v-text-field v-model='textSearch'
                                  filled
                                  @input='getTracking'
                                  label='ค้นหาสินค้า'
                                  dense
                                  hide-details
                                  placeholder='คำค้นหา'></v-text-field>
                </v-col>
            </v-row>
            <v-data-table
                    :headers='headers'
                    :items='dataList'
                    v-model="selectedRows"
                    item-key="order_id"
                    show-select
                    :items-per-page='itemsPerPage'
                    :loading='loading'
                    no-data-text='ไม่มีข้อมูล !'
                    loading-text='กำลังโหลดข้อมูล...'
                    :footer-props='footer'
                    no-results-text='ไม่มีข้อมูล !'
                    class='elevation-1'
            >
                <template #[`item.order_transportation_status`]='{ item }'>
                    <StatusOrderTransport :status='String(item.order_transportation_status)'/>
                </template>
                <template #[`item.customer_fname`]='{ item }'>
                    ({{ item.customer_code }}) {{ item.customer_fname }}
                </template>
                <template #[`item.order_transportation_status`]='{ item }'>
                    <StatusOrderTransport :status='String(0)'/>
                </template>
                <template #[`item.order_transportation_date`]='{ item }'>
                    {{ item.order_transportation_date | sumdate }}
                </template>
                <template #[`item.order_total`]='{ item }'>
                    {{ item.order_total | formatPrice }}
                    <CurrentCurrency/>
                </template>
                <template #[`item.actions`]='{ item }'>
                    <v-tooltip top>
                        <template v-slot:activator='{ on, attrs }'>
                            <v-btn
                                    color='primary'
                                    @click="dataEdit = item, isCreateLink = true"
                                    icon
                                    v-bind='attrs'
                                    v-on='on'
                            >
                                <v-icon>{{ mdiLinkVariant }}</v-icon>
                            </v-btn>
                        </template>
                        <span>สร้างลิงค์ตรวจสอบสถานะสำหรับลูกค้า</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog v-model="isImport" persistent max-width="400">
            <v-card>
                <v-card-title>
                    นำเข้าไฟล์ Tracking
                    <v-spacer></v-spacer>

                </v-card-title>
                <v-divider></v-divider>
                <v-row class="mx-2 my-2">
                    <v-col cols="12">
                        <v-file-input
                                label="เลือกไฟล์"
                                filled
                                hide-details
                                accept=".xlsx, .xls"
                                v-model="fileImportTracking"
                                :prepend-icon="mdiPaperclip"
                        ></v-file-input>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="importTracking" :loading="importLoading"
                           :disabled="importLoading || !fileImportTracking">ยืนยัน
                    </v-btn>
                    <v-btn color="secondary" outlined :loading="importLoading" :disabled="importLoading"
                           @click="isImport = false">ยกเลิก
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
        <v-dialog v-model="isCreateLink" persistent max-width="500">
            <v-card>
                <v-card-title>
                    สร้างลิงค์ตรวจสอบสถานะสำหรับลูกค้า
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-text-field filled v-model='order_transportation_text' dense label='ข้อความแจ้งลูกค้า'
                                  placeholder='ข้อความแจ้งลูกค้า'>

                    </v-text-field>
                    <div v-if="dataLink.link">
                        <b>ลิงค์ : </b> {{ dataLink.link }}
                        <v-icon color="primary"
                                v-clipboard:copy="dataLink.link"
                                v-clipboard:success="copy"
                                style="cursor: pointer">{{ mdiContentCopy }}
                        </v-icon>
                    </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="generateLink">ยืนยัน</v-btn>
                    <v-btn color="secondary" outlined @click="isCreateLink = false">ปิด</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import FilterStatus from '@/components/FilterStatus'
import StatusBlock from '@/components/StatusBlock'
import { mdiTruckCheckOutline, mdiCloudDownload, mdiPaperclip, mdiLinkVariant, mdiContentCopy } from '@mdi/js'
import useTracking from "./useTracking";
import StatusOrderTransport from "@/components/StatusOrderTransport";
import { sumdatetime, formatPrice, sumdate } from "@/services/filters";
import CurrentCurrency from "@/components/CurrentCurrency";
import DateFilters from "@/components/DateFilters";
import formImport from '@/assets/download_files/product.xlsx'

export default {
    props: {},
    components: {
        CurrentCurrency,
        FilterStatus,
        DateFilters,
        StatusBlock,
        StatusOrderTransport,
    },
    filters: {
        sumdatetime, formatPrice, sumdate
    },
    setup(_, { root, emit }) {
        return {
            ...useTracking(),
            mdiPaperclip,
            mdiCloudDownload,
            formImport,
            mdiLinkVariant,
            mdiTruckCheckOutline,
            mdiContentCopy,
        }
    },

}
</script>

<style scoped>

</style>
